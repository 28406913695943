import React, { useState, useEffect } from 'react';
import '../flight-booking-main/flight-booking-main.css'
import FlightBookingMainLeft from '../flight-booking-main/flight-booking-main-left'
import FlightBookingMainRight from '../flight-booking-main/flight-booking-main-right'
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

const FlightBookingMain = ({
  uData,
  data,
  traveltype,
  tripinfo,
  onUpdate,
  airlines,
  triptype,
  isisnetfarefromback,
}) => {
  // const location = useLocation();
  // const FlightData = data[0].flight;
  // const Search_Key = data[0].Search_Key;
  // const fareid = data[0].fareid;
  const charges = data[0].charges;
  const adult = data[0].adultcount;
  // const faredetails = data[0].item;
  let sum = 0;
  data.forEach((element) => {
    sum =
      sum +
      (Number(
        element.item.FareDetails.find((fare) => fare.PAX_Type === 0)
          ?.Total_Amount || 0
      ) *
        Number(adult.adult || 0) +
        Number(charges) +
        Number(
          element.item.FareDetails.find((fare) => fare.PAX_Type === 1)
            ?.Total_Amount || 0
        ) *
          Number(adult.child || 0) +
        Number(
          element.item.FareDetails.find((fare) => fare.PAX_Type === 2)
            ?.Total_Amount || 0
        ) *
          Number(adult.infant || 0)) +
      // (uData && uData.type === "2"?(element.item.FareDetails.find((fare) => fare.PAX_Type === 0)?.TDS *
      //   Number(data[0].adultcount.adult) +
      //   Number(
      //     element.item.FareDetails.find((fare) => fare.PAX_Type === 1)?.TDS || 0
      //   ) *
      //     Number(data[0].adultcount.child) +
      //   Number(
      //     element.item.FareDetails.find((fare) => fare.PAX_Type === 2)?.TDS || 0
      //   ) *
      //     Number(data[0].adultcount.infant)):0) +
      (element.item.FareDetails.find((fare) => fare.PAX_Type === 0)
        ?.Service_Fee_Amount *
        Number(data[0].adultcount.adult) +
        Number(
          element.item.FareDetails.find((fare) => fare.PAX_Type === 1)
            ?.Service_Fee_Amount || 0
        ) *
          Number(data[0].adultcount.child) +
        Number(
          element.item.FareDetails.find((fare) => fare.PAX_Type === 2)
            ?.Service_Fee_Amount || 0
        ) *
          Number(data[0].adultcount.infant));
  });

  const [selectedssrOptions, setSelectedssrOptions] = useState([]);
  const ssrsum = selectedssrOptions.reduce((total, item) => {
    return total + item.Total_Amount; // Add the amount if it exists, otherwise add 0
  }, 0);

  const bookingamount = Number(sum) + Number(ssrsum);
  let sumwithcommission = 0;
  data.forEach((element) => {
    sumwithcommission =
      sumwithcommission +
      ((Number(
        element.item.FareDetails.find((fare) => fare.PAX_Type === 0)
          ?.Total_Amount || 0
      ) -
        (Number(
          element.item.FareDetails.find((fare) => fare.PAX_Type === 0)
            ?.Net_Commission || 0
        ) *
          Number(
            uData && uData.type === "2" ? uData.agents.flight_booking_c : "0"
          )) /
          100 +
        Number(
          uData && uData.type === "2"
            ? element.item.FareDetails.find((fare) => fare.PAX_Type === 0)
                ?.TDS || 0
            : 0
        )) *
        Number(adult.adult || 0) +
        Number(charges) +
        (Number(
          element.item.FareDetails.find((fare) => fare.PAX_Type === 1)
            ?.Total_Amount || 0
        ) -
          (Number(
            element.item.FareDetails.find((fare) => fare.PAX_Type === 1)
              ?.Net_Commission || 0
          ) *
            Number(
              uData && uData.type === "2" ? uData.agents.flight_booking_c : "0"
            )) /
            100 +
          Number(
            uData && uData.type === "2"
              ? element.item.FareDetails.find((fare) => fare.PAX_Type === 1)
                  ?.TDS || 0
              : 0
          )) *
          Number(adult.child || 0) +
        (Number(
          element.item.FareDetails.find((fare) => fare.PAX_Type === 2)
            ?.Total_Amount || 0
        ) -
          (Number(
            element.item.FareDetails.find((fare) => fare.PAX_Type === 2)
              ?.Net_Commission || 0
          ) *
            Number(
              uData && uData.type === "2" ? uData.agents.flight_booking_c : "0"
            )) /
            100 +
          Number(
            uData && uData.type === "2"
              ? element.item.FareDetails.find((fare) => fare.PAX_Type === 2)
                  ?.TDS || 0
              : 0
          )) *
          Number(adult.infant || 0)) +
      // (uData && uData.type === "2"
      //   ? element.item.FareDetails.find((fare) => fare.PAX_Type === 0)?.TDS *
      //       Number(data[0].adultcount.adult) +
      //     Number(
      //       element.item.FareDetails.find((fare) => fare.PAX_Type === 1)?.TDS ||
      //         0
      //     ) *
      //       Number(data[0].adultcount.child) +
      //     Number(
      //       element.item.FareDetails.find((fare) => fare.PAX_Type === 2)?.TDS ||
      //         0
      //     ) *
      //       Number(data[0].adultcount.infant)
      //   : 0) +
      (element.item.FareDetails.find((fare) => fare.PAX_Type === 0)
        ?.Service_Fee_Amount *
        Number(data[0].adultcount.adult) +
        Number(
          element.item.FareDetails.find((fare) => fare.PAX_Type === 1)
            ?.Service_Fee_Amount || 0
        ) *
          Number(data[0].adultcount.child) +
        Number(
          element.item.FareDetails.find((fare) => fare.PAX_Type === 2)
            ?.Service_Fee_Amount || 0
        ) *
          Number(data[0].adultcount.infant));
  });
  const bookingamountwithcommission =
    Number(sumwithcommission) + Number(ssrsum);
  // Number(faredetails.FareDetails.at(0).GST ?? "0") * adult.adult +
  // Number(faredetails.FareDetails.at(0).GST ?? "0") * adult.child;
  //  +Details.item.FareDetails.find((fare) => fare.PAX_Type === 0)?.GST *
  //    Number(data[0].adultcount.adult) +
  //    Number(
  //      Details.item.FareDetails.find((fare) => fare.PAX_Type === 1)?.GST || 0
  //    ) *
  //      Number(data[0].adultcount.child) +
  //    Number(
  //      Details.item.FareDetails.find((fare) => fare.PAX_Type === 2)?.GST || 0
  //    ) *
  //      Number(data[0].adultcount.infant);

  return (
    <section className="flight-booking">
      <div className="contain">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="row">
          <FlightBookingMainLeft
            data={data}
            traveltype={traveltype}
            tripinfo={tripinfo}
            bookingamount={bookingamount}
            bookingamountwithcommission={bookingamountwithcommission}
            onupdatessr={(updatedItem) => {
              setSelectedssrOptions(updatedItem.list);
            }}
            triptype={triptype}
          />

          <FlightBookingMainRight
            data={data}
            uData={uData}
            ssrselect={selectedssrOptions}
            bookingamount={bookingamount}
            bookingamountwithcommission={bookingamountwithcommission}
            onUpdate={(updatedItem) => {
              onUpdate({});
            }}
            airlines={airlines}
            isisnetfarefromback={isisnetfarefromback}
          />
        </div>
      </div>
    </section>
  );
};
// aa

export default FlightBookingMain;